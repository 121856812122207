<template>
    <div>

        <!-- Header-Image Section -->
        <section class="header-image">
            <div class="container text-center">
                <h1 class="text-white">PharmaConnect | Nos Services</h1>
            </div>

        </section>

        <!-- Services Section -->
        <section class="key-benefits">
            <div class="container">
                <h2 class="agent">Pharmaciens</h2>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/order.png')"
                                alt="Pharmaciens Commande Simplifiée" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Commande Simplifiée</h2>
                        <p>Passez des commandes rapidement et efficacement chez n'importe quel grossiste en un seul
                            endroit.</p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/documentation.png')"
                                alt="Pharmaciens Suivi des Documents" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Suivi des Documents</h2>
                        <p>Consultez et imprimez vos factures/avoirs/retouts et bons de livraison en temps réel.</p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/notification.png')"
                                alt="Pharmaciens Être à jour" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Être à jour</h2>
                        <p>Consultez les circulaires, les annances et les nouveautés du secteur directement sur la
                            plateforme.</p>
                    </div>
                </div>

                <hr>

                <h2 class="agent">Fournisseurs</h2>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/in-stock.png')"
                                alt="Fournisseurs Catalogues Des Articles" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Catalogues Des Articles</h2>
                        <p>Ajoutez/supprimer et modifier vos articles pour une meilleure
                            commercialisation sur pharmaconnect.</p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/checklist.png')"
                                alt="Fournisseurs Suivi des Articles" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Suivi des Articles</h2>
                        <p>Obtenez des statistiques sur les ventes de vos articles chez tous les grossites en un seul
                            endroit.</p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/pie-chart.png')"
                                alt="Fournisseurs Statistiques en Temps Réel" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Statistiques en Temps Réel</h2>
                        <p>Accédez aux données de vente en temps réel pour une meilleure analyse.</p>
                    </div>
                </div>

                <hr>

                <h2 class="agent">Grossistes</h2>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/transformation.png')"
                                alt="Grossistes Gestion des Commandes" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Gestion des Commandes</h2>
                        <p>Suivez et gérez les commandes en ligne et évitez les transactions téléphoniques
                            réactionnaires.</p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/analysis.png')"
                                alt="Grossistes Statistiques de Vente" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Statistiques de Vente</h2>
                        <p>Accédez à des statistiques avancées sur les ventes pour une meilleure analyse du marché et
                            des produits.
                        </p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/release.png')"
                                alt="Grossistes Gestion des Réclamations" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Gestion des Réclamations</h2>
                        <p>Améliorez la satisfaction des clients en gérant efficacement les réclamations et en assurant
                            un suivi
                            rapide et précis.</p>
                    </div>
                </div>

            </div>
        </section>

        <br>

    </div>

</template>

<script>
export default {
    metaInfo() {
        return {
            title: 'PharmaConnect | Nos Services',
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content: "Découvrez les services de PharmaConnect, la plateforme qui connecte les pharmaciens, les grossistes, et les fournisseurs en Tunisie pour une chaîne plus efficace."
                },
                {
                    name: 'keywords',
                    content: 'PharmaConnect, Pharmacie, Grossistes, Fournisseurs, Tunisie, Pharmaciens, Plateforme Pharmaceutique, parapharmacie,parapharmaceutique, Commandes en Ligne'
                },
                {
                    name: 'author',
                    content: 'DIGITHINK CONSULTING'
                },
                {
                    property: 'og:title',
                    content: 'PharmaConnect | Nos Services'
                },
                {
                    property: 'og:description',
                    content: "Découvrez les services de PharmaConnect, la plateforme qui connecte les pharmaciens, les grossistes, et les fournisseurs en Tunisie pour une chaîne d'approvisionnement plus efficace."
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    property: "og:image",
                    content: require('@/assets/images/logo/logo.svg'),
                },
                {
                    property: "og:url",
                    content: "https://www.pharmaconnect.net/services",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: "PharmaConnect | Nos Services",
                },
                {
                    name: "twitter:description",
                    content: "Découvrez les services de PharmaConnect, la plateforme qui connecte les pharmaciens, les grossistes, et les fournisseurs en Tunisie pour une chaîne d'approvisionnement plus efficace."
                },
                {
                    name: "twitter:image",
                    content: require('@/assets/images/logo/logo.svg'),
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://www.pharmaconnect.net/services",
                },
            ],
            script: [
                {
                    type: "application/ld+json",
                    json: {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "PharmaConnect | Nos Services",
                        "description": "Découvrez les services de PharmaConnect, la plateforme qui connecte les pharmaciens, les grossistes, et les fournisseurs en Tunisie pour une chaîne d'approvisionnement plus efficace.",
                        "brand": {
                            "@type": "Brand",
                            "name": "PharmaConnect"
                        }
                    },
                },
            ],
        };
    },
};
</script>

<style scoped>
.header-image {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('~@/assets/images/website/page-header-bg.jpg') no-repeat center center;
    background-size: cover;
    color: white;
    padding: 50px 0;
}

.icon-container {
    font-size: 2.5rem;
    color: #6c63ff;
}

.agent {
    justify-self: center;
    padding: 50px;
    /* color: #28c76f; */
    font-weight: bold;
}

.utilities {
    padding: 20px;
}
</style>